import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Landing from './landing-page';
import Resume from './resume';
import Projects from './projects';

const Main = () => (
    <Switch>
        <Route exact path={process.env.PUBLIC_URL + '/'} component={Landing}/>
        <Route path={process.env.PUBLIC_URL + '/resume'} component={Resume}/>
        <Route path={process.env.PUBLIC_URL + '/projects'} component={Projects}/>
    </Switch>
)

export default Main;