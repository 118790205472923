import { Grid, Zoom } from '@material-ui/core';
import React, { Component } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Button, Card, CardActions, CardText, CardTitle, Dialog, DialogActions, DialogContent, DialogTitle } from 'react-mdl';
import SimpleImageSlider from 'react-simple-image-slider';
import imgBluetooth from '../assets/projects/bluetooth.png';
import imgRealEstate from '../assets/projects/real_estate.png';
import imgTravelar from '../assets/projects/travelar.svg';
import imgTourList from '../assets/tourlist.png';
import imgTourDetails from '../assets/tour_details.jpg';
import imgTourDetailsAdmin from '../assets/tour_details_admin.jpg';
import imgTourMap from '../assets/tour_map.jpg';
import profilePic from './IMG_5915.JPG';

const images = [
    { url: imgTourList },
    { url: imgTourDetails },
    { url: imgTourDetailsAdmin },
    { url: imgTourMap }
];

class Landing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isFlipped1: false,
            isFlipped2: false,
            isFlipped3: false,
            showDialog: false,
        }

        this.handleClickUI = this.handleClickUI.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    onMouseEnterProject = (proj) => {
        if (proj === 1) {
            this.setState(prevState => ({ isFlipped1: !prevState.isFlipped1 }));
        } else if (proj === 2) {
            this.setState(prevState => ({ isFlipped2: !prevState.isFlipped2 }));
        } else if (proj === 3) {
            this.setState(prevState => ({ isFlipped3: !prevState.isFlipped3 }));
        }

    }

    handleClickUI() {
        this.setState({ showDialog: true });
    }

    handleCloseDialog() {
        this.setState({ showDialog: false });
    }

    render() {
        return (
            <div style={{ flexGrow: 1 }}>
                <Dialog open={this.state.showDialog} onCancel={this.handleCloseDialog}>
                    <DialogTitle>TravelAR</DialogTitle>
                    <DialogContent>
                        <SimpleImageSlider style={{ margin: 'auto' }}
                            width={175}
                            height={350}
                            images={images}
                            showNavs={false}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Grid container justify='center'>
                    <Grid item>
                        <img src={profilePic}
                            alt="avatar"
                            className="avatar-img"
                        />
                    </Grid>
                </Grid>
                <Grid container justify='center' className='banner-text'>
                    <h1 style={{ fontSize: 36, marginBottom: 0 }}>Mo Moussa</h1>
                </Grid>
                <Grid container justify='center' className='banner-text'>
                    <h2 style={{ fontSize: 28, margin: 0 }}>Software Developer</h2>
                </Grid>
                <Grid container justify='center' className='social-links' spacing={3}>
                    <Grid item>
                        <a href="https://www.linkedin.com/in/moussamo/" rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-linkedin-square" aria-hidden="true" />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://github.com/mmoussa" rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-github-square" aria-hidden="true" />
                        </a>
                    </Grid>
                </Grid>
                <Zoom in={true}>
                    <Grid container justify='center' spacing={8}>
                        <Grid item onMouseEnter={() => this.onMouseEnterProject(1)} onMouseLeave={() => this.onMouseEnterProject(1)}>
                            <ReactCardFlip isFlipped={this.state.isFlipped1}>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'url(' + imgBluetooth + ') center no-repeat',
                                    backgroundSize: '100%'
                                }}>
                                </Card>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'white',
                                    backgroundSize: '100%'
                                }}>
                                    <CardTitle>Indoor Localization</CardTitle>
                                    <CardText>An iOS indoor localization and asset tracking
                                        app utilizing Bluetooth iBeacons.
                                </CardText>
                                    <CardActions>
                                        <Button colored href='https://youtu.be/zaMCRUGKrkQ' target='_blank'>Demo 1</Button>
                                        <Button colored href='https://drive.google.com/file/d/0B1HoZYxHuroJcTc4ZnI3MllSeTg/view' target='_blank'>Demo 2</Button>
                                    </CardActions>
                                </Card>
                            </ReactCardFlip>

                        </Grid>
                        <Grid item onMouseEnter={() => this.onMouseEnterProject(2)} onMouseLeave={() => this.onMouseEnterProject(2)}>
                            <ReactCardFlip isFlipped={this.state.isFlipped2}>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'url(' + imgTravelar + ') center no-repeat',
                                    backgroundSize: '100%'
                                }}>
                                </Card>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'white',
                                    backgroundSize: '100%'
                                }}>
                                    <CardTitle>TravelAR</CardTitle>
                                    <CardText>
                                        A travel companion app powered by AR and image recognition to
                                        provide a more enjoyable tour experience.
                                </CardText>
                                    <CardActions>
                                        <Button colored onClick={this.handleClickUI}>UI</Button>
                                        <Button colored href='https://youtu.be/IpNlb_yzcb4' target='_blank'>Demo</Button>
                                    </CardActions>
                                </Card>
                            </ReactCardFlip>

                        </Grid>
                        <Grid item onMouseEnter={() => this.onMouseEnterProject(3)} onMouseLeave={() => this.onMouseEnterProject(3)}>
                            <ReactCardFlip isFlipped={this.state.isFlipped3}>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'url(' + imgRealEstate + ') center no-repeat',
                                    backgroundSize: '100%'
                                }}>
                                </Card>
                                <Card shadow={5} style={{
                                    width: 200,
                                    background: 'white',
                                    backgroundSize: '100%'
                                }}>
                                    <CardTitle>Real Estate Finder</CardTitle>
                                    <CardText>A hybrid mobile app that finds the optimal
                                        residential areas based on the user's criteria.
                                </CardText>
                                    <CardActions>
                                        <Button colored href='https://drive.google.com/file/d/0B1HoZYxHuroJT3RQaVBNckZfbEk/view' target='_blank'>Demo</Button>
                                    </CardActions>
                                </Card>
                            </ReactCardFlip>


                        </Grid>

                    </Grid>
                </Zoom>
                {/* <Grid className="landing-grid">
                    <Cell col={12}>
                        <img src={profilePic}
                            alt="avatar"
                            className="avatar-img"
                        /> */}

                {/* <div className="banner-text">
                            <h1>Mo Moussa</h1>
                            <h2>Software Developer</h2>
                            <div className="social-links">
                                <Cell col={6}> */}
                {/* LinkedIn */}
                {/* <a href="https://www.linkedin.com/in/moussamo/" rel="noopener noreferrer" target="_blank">
                                        <i className="fa fa-linkedin-square" aria-hidden="true" />
                                    </a>
                                </Cell>
                                <Cell col={6}> */}
                {/* Github */}
                {/* <a href="https://github.com/mmoussa" rel="noopener noreferrer" target="_blank">
                                        <i className="fa fa-github-square" aria-hidden="true" />
                                    </a>
                                </Cell>
                            </div>
                        </div>
                    </Cell> */}
                {/* <Cell col={4} phone={4}>
                        <Card className='project-card' shadow={5} style={{
                            height: '100%',
                            background: 'url(' + imgBluetooth + ') center no-repeat',
                            backgroundSize: '100%'
                        }}>
                        </Card>
                    </Cell>
                    <Cell col={4} phone={4}>
                        <Card className='project-card' shadow={5} style={{
                            height: '100%',
                            background: 'url(' + imgTravelar + ') center no-repeat',
                            backgroundSize: '100%'
                        }}>
                        </Card>
                    </Cell>
                    <Cell col={4} phone={4}>
                        <Card className='project-card' shadow={5} style={{
                            height: '100%',
                            background: 'url(' + imgRealEstate + ') center no-repeat',
                            backgroundSize: '100%'
                        }}>
                        </Card>
                    </Cell> */}

                {/* </Grid> */}
            </div>
        )
    }
}

export default Landing;