import { Chip, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { Cell, Grid } from 'react-mdl';

class Resume extends Component {
    render() {

        return (
            <div>
                <Grid className="resume-grid">
                    <Cell col={12}>
                        <h1 style={{ fontSize: 28 }}>Resume</h1>
                    </Cell>
                    <Cell col={12}>
                        <h1>Skills</h1>
                    </Cell>
                    <Cell col={4} offsetDesktop={4}>
                        <Chip label='C' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='C++' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='C#' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Java' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Python' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='JavaScript' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Swift' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='SQL' color='primary' style={{ margin: 1 }}></Chip>
                    </Cell>
                    <Cell col={4} offsetDesktop={4}>
                        <Chip label='React' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='React-Native' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Bootstrap' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Scikit-Learn' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='TensorFlow' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Keras' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='ARCore' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='ASP.NET' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='WCF' color='primary' style={{ margin: 1 }}></Chip>
                    </Cell>
                    <Cell col={4} offsetDesktop={4}>
                        <Chip label='Windows' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Linux' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='OS X' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Git' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='SVN' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Google Cloud' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Microsoft Azure' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Unity' color='primary' style={{ margin: 1 }}></Chip>
                        <Chip label='Unreal Engine 4' color='primary' style={{ margin: 1 }}></Chip>
                    </Cell>
                    <Cell col={12}>
                        <h1>Education</h1>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Sheridan College</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <span><b>Honours Bachelor of Computer Science - Mobile Computing</b> Oakville, ON, Canada</span>
                                    <p>Sep 2015 - Dec 2019</p>
                                    <p>• <b>High Honours</b> student, <b>cumulative GPA: 3.85/4.0</b></p>
                                    <p>• <b>Relevant Courses:</b> Data Structures and Algorithms,
                                        Artificial Intelligence, Cloud Computing, Ubiquitous Computing,
                                        Cognitive Computing, Business Intelligence and Data Mining,
                                        Operating Systems</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>University of Toronto</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <span><b>Electrical Engineering</b> Toronto, ON, Canada</span>
                                    <p>Sep 2012 - Dec 2014</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={12}>
                        <h1>Experience</h1>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Virtual Humans Research Assistant</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <span><b>Screen Industries Research and Training Centre (SIRT)</b> Toronto, ON, Canada</span>
                                    <p>Apr 2019 - Aug 2019</p>
                                    <p>• Researched and developed speech-driven animation for 3D characters using machine learning</p>
                                    <p>• Developed a face tracking plugin for Adobe After Effects in C++</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Junior Programmer</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <span><b>Screen Industries Research and Training Centre (SIRT)</b> Toronto, ON, Canada</span>
                                    <p>Jan 2018 - Aug 2018</p>
                                    <p>• Developed, tested, and debugged virtual reality applications in C++ and Java</p>
                                    <p>• Created content in Unreal Engine to demo wireless VR technology on the GearVR</p>
                                    <p>• Developed a gameplay recording plugin for Unreal Engine in C++</p>
                                    <p>• Added support for developing games utilizing EEG headbands in Unreal Engine (C++)</p>
                                    <p>• Developed a visualization tool to test and debug a hand tracking application on iOS (Swift/Objective C/C++)</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Research Assistant and Programmer</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <span><b>Centre for Mobile Innovation, Sheridan College</b> Oakville, ON, Canada</span>
                                    <p>Jan 2017 - Jan 2018</p>
                                    <p>• Researched methods of indoor localization and asset tracking using Bluetooth iBeacons</p>
                                    <p>• Developed iOS location tracking applications in Swift, as well as apps to manage and calibrate the beacons</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={12}>
                        <h1>Projects</h1>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>TravelAR</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p><b>Top 3 Capstone Project.</b> A travel mobile app utilizing mobile AR and landmark
                                     recognition to provide a better travel/tour experience.</p>
                                    <p><b>Technologies Used:</b> Google Cloud Vision, AutoML Vision, Firebase, ARCore</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>MedAware</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>Designed an app for wearable devices to aid seniors living on their own.
                                        Received an <b>honourable mention</b> in <b>Microsoft’s Imagine Cup 2016 Design Challenge.</b></p>
                                    <p><b>Technologies Used:</b> Microsoft Azure, C# and UWP (Universal Windows Platform),
                                         and Microsoft Band wearable.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Real Estate Finder</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>A hybrid mobile app that finds the optimal residential areas based on the user’s criteria.</p>
                                    <p><b>Technologies Used:</b> Cordova, JavaScript, jQuery Mobile, and Bootstrap.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                    <Cell col={8} offsetDesktop={2} phone={4}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><h1>Travel Planner</h1></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>A web application that helps the user plan out their trips. </p>
                                    <p><b>Technologies Used:</b> ASP.NET MVC, SQL and WCF</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Cell>
                </Grid>
            </div>
        )
    }
}

export default Resume;