import React, { Component } from 'react';
import { Button, Card, CardActions, CardText, CardTitle, Dialog, DialogActions, DialogContent, DialogTitle } from 'react-mdl';
import SimpleImageSlider from 'react-simple-image-slider';
import imgTourList from '../assets/tourlist.png';
import imgTourDetails from '../assets/tour_details.jpg';
import imgTourDetailsAdmin from '../assets/tour_details_admin.jpg';
import imgTourMap from '../assets/tour_map.jpg';
import travelarLogo from './ic_travelar.svg';

const images = [
    { url: imgTourList },
    { url: imgTourDetails },
    { url: imgTourDetailsAdmin },
    { url: imgTourMap }
];

class Projects extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    handleOpenDialog() {
        this.setState({
            openDialog: true
        });
    }

    handleCloseDialog() {
        this.setState({
            openDialog: false
        });
    }



    render() {
        return (
            <div className="projects-grid">
                {/*Dialog*/}
                <Dialog open={this.state.openDialog} onCancel={this.handleCloseDialog}>
                    <DialogTitle>TravelAR</DialogTitle>
                    <DialogContent>
                        <SimpleImageSlider style={{ margin: 'auto' }}
                            width={175}
                            height={350}
                            images={images}
                            showNavs={false}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* Project 1 */}
                <Card className="project-card" shadow={5}>
                    <CardTitle className='card-title' style={{
                        background:
                            'url(' + travelarLogo + ') center no-repeat', backgroundSize: '30%'
                    }}></CardTitle>
                    <CardTitle>TravelAR</CardTitle>
                    <CardText className='card-text'>A travel companion app powered by AR and image recognition to provide a more enjoyable tour experience.</CardText>
                    <CardActions border>
                        <Button href='https://youtu.be/GDemT3E-T2s' target='_blank' colored>Demo 1</Button>
                        <Button href='https://youtu.be/IpNlb_yzcb4' target='_blank' colored>Demo 2</Button>
                        <Button onClick={this.handleOpenDialog} colored>UI</Button>
                    </CardActions>
                </Card>

                {/* Project 2 */}
                <Card className="project-card" shadow={5}>
                    <CardTitle className='card-title' style={{
                        background:
                            'url(http://pngimg.com/uploads/bluetooth/bluetooth_PNG43.png) center no-repeat', backgroundSize: '30%'
                    }}></CardTitle>
                    <CardTitle>Bluetooth Location Tracking</CardTitle>
                    <CardText className='card-text'>An iOS location tracking app using Bluetooth iBeacons.</CardText>
                    <CardActions border>
                        <Button href='https://drive.google.com/file/d/0B1HoZYxHuroJcTc4ZnI3MllSeTg/view' target='_blank' colored>Demo</Button>
                    </CardActions>
                </Card>

                {/* Project 3 */}
                <Card className="project-card" shadow={5}>
                    <CardTitle className='card-title' style={{
                        background:
                            'url(https://amp.businessinsider.com/images/5bdc87448c35ab6a120935eb-1536-1152.jpg) top 5% center no-repeat', backgroundSize: '100%'
                    }}></CardTitle>
                    <CardTitle>Real Estate Finder</CardTitle>
                    <CardText className='card-text'>A hybrid mobile app that finds the optimal residential areas based on the user’s criteria.</CardText>
                    <CardActions border>
                        <Button href='https://drive.google.com/file/d/0B1HoZYxHuroJT3RQaVBNckZfbEk/view' target='_blank' colored>Demo</Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

export default Projects;